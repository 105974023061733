import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Started With Open Source`}</h1>
    <p>{`I have finished the course - introduction to computer science with python - and I was stuck in `}<a parentName="p" {...{
        "href": "https://theflying.dev/digital-garden/tutorial-hell"
      }}>{`tutorial hell`}</a>{`, when I made my first PR to opsdroid, a chat-ops framework written in Python and a project that I am now a maintainer.`}</p>
    <p>{`I've always wanted to contribute to open source one way or another. Not only it is a great way to give back to the community,  it can also help you grow as a developer. I have looked into some of the most popular projects in Github but had no clue where and how to start contributing.`}</p>
    <p>{`I started listening to a lot of podcasts related to Python and life as a dev. On one of the podcasts called `}<a parentName="p" {...{
        "href": "https://www.pythonpodcast.com/"
      }}>{`Podcast.`}{`_`}{`_`}{`init`}{`_`}{`_`}</a>{` , Jacob Tomlinson got interviewed and talked about opsdroid [`}<a parentName="p" {...{
        "href": "https://www.pythonpodcast.com/opsdroid-with-jacob-tomlinson-episode-124/"
      }}>{`Link to the episode`}</a>{`]. The project seemed pretty cool and at the end of the podcast, he said that he would love to have people contribute to the project.`}</p>
    <p>{`The next day I opened my laptop, checked opsdroid and read the documentation on how to use it. Also  I wanted to figure out what sort of things I could help with.`}</p>
    <p>{`My first PR was pretty simple, I changed the google skill to include other search engines. After this PR got merged, I helped a bit with documentation and started to be more comfortable with Github and the opsdroid project. Slowly my confidence improved and I took on more challenging tasks.`}</p>
    <p>{`Now that I have shared with you how I got started with contributing to open source, I will share with you a few tips if you want to get started today!`}</p>
    <h1>{`Tips To Get Started With Open Source`}</h1>
    <p>{`Contributing to open source may be a great learning experience and every contribution, no matter how small, will help a project. `}</p>
    <p>{`The great thing about Open Source is that you can help in various ways, not only with code.`}</p>
    <p>{`Reporting bugs, helping the maintainers/creator(s) with open Pull Requests, fixing typos, improving documentation by either adding or extending existing documentation and share a project that you love are all great ways to get started with Open Source.`}</p>
    <h2>{`Read The Contributing File`}</h2>
    <p>{`Different projects have different rules/styles of contributing to a project. These rules and directions are usually described in the `}{`\``}{`CONTRIBUTING.md`}{`\``}{` file. Make sure to read this file before jumping into your editor.`}</p>
    <h2>{`Look At Open Issues`}</h2>
    <p>{`Have a look at open issues. Usually, you can see issues tagged with different things and a lot of projects have tags that are aimed at a new contributor - "Good First Issue", "Beginner" or "Low Level" are all tags that are commonly used to mark an issue that is great for a new contributor.`}</p>
    <p>{`You can also have a look at other issues. If you see one that seems interesting to work with, feel free to ask a question  on the issue to check if is still open and if you could work on it. Maintainers/creator(s) are often quite welcoming to new people wanting to help with their project.`}</p>
    <h2>{`Check The Documentation`}</h2>
    <p>{`Sometimes submitting Pull Requests that aim to fix documentation makes you feel that you didn't contribute much since you didn't touch the codebase. But documentation is one of the most important things you can help within a project. `}</p>
    <p>{`Fixing typos, updating or extending documentation are all ways that you may help with. Another great thing when you try to help with the documentation is that it makes you more comfortable with how the codebase works and it will be easier for you to jump in and change things in the code.`}</p>
    <h2>{`Learn Git`}</h2>
    <p>{`You may contribute to a project directly from the Github website, but being comfortable with the terminal is a great tool to have under your belt. Git is a version control system that allows you to keep track of changes in a project. `}</p>
    <p>{`You will need to know how to use Git if you want to work with GitHub (or other alternatives like GitLab) from the terminal window.`}</p>
    <p>{`The most common commands that you will use will be:`}</p>
    <p><inlineCode parentName="p">{`git checkout -b <branch name>`}</inlineCode>{` - Create a new branch and start working on said branch`}</p>
    <p><inlineCode parentName="p">{`git add <file or use . to add all>`}</inlineCode>{` - Adds changes to the staging environment (can be commited and then pushed to github)`}</p>
    <p><inlineCode parentName="p">{`git commit -m <message>`}</inlineCode>{` - Commit changes from the stating environment and add a message to that commit.`}</p>
    <p><inlineCode parentName="p">{`git push`}</inlineCode>{` - Push the changes to GitHub `}</p>
    <p>{`You can learn more about Git on `}<a parentName="p" {...{
        "href": "https://www.codecademy.com/learn/learn-git"
      }}>{`Git Tutorial: Learn Git Basics - Codecademy`}</a></p>
    <h2>{`It Can Be Scary`}</h2>
    <p>{`Contributing to Open Source might be scary because you are trying to understand a project with little or no knowledge of how the codebase works. Don't worry if you don't understand the whole codebase, this knowledge will come with time if you decide to stick with a project for a long time.`}</p>
    <p>{`The easier way to get started is by checking the documentation so you can get an understanding about how things work, then try to pick one thing in the codebase and follow it through to its base. For example, you want to know how a method works, find a place where that method is being called, go deeper until you reach the code for that class and start reading the code and comment to try and understand how that method behaves.`}</p>
    <h2>{`Don't Worry If You Are Stuck`}</h2>
    <p>{`A lot of times I have gotten stuck with tests. A lot of times I have spent two days working on something and felt stuck because I couldn't make it work. It's okay and everyone will `}<a parentName="p" {...{
        "href": "https://theflying.dev/digital-garden/embrace-being-stuck"
      }}>{`eventually get stuck`}</a>{`. `}</p>
    <p>{`Remember that this is all a learning experience and you will grow a lot from it. Do your research, check google and stackoverflow and if you are still stuck, ask for help, most likely someone will be able to guide you and help you get unstuck.`}</p>
    <h2>{`Tests, Tests, Tests`}</h2>
    <p>{`Tests are extremely important. A lot of projects try to have a great coverage percentage of tests. If you haven't written tests before, it can be daunting to try and make one pass. Don't worry if you get stuck, just make sure you check how other functions/methods are being tested and a lot of times you will be able to re-use some of those tests or logic into your own.`}</p>
    <p>{`At first, tests seem like a magical thing and can be hard to get the grasp of it. Stick to it and after a while, things will start making sense. Feel free to read more about testing and how to test different things, it will help in the long run.`}</p>
    <h2>{`Find a Good Project`}</h2>
    <p>{`Finding a good project might be hard. If you are just starting with open source, you might feel like trying to contribute to a big project. `}</p>
    <p>{`Keep in mind that contributing to big projects might be hard because the codebase is very big, the coding style is more mature and polished. It also might be harder to add changes to a large codebase if your programming skills are not great yet.`}</p>
    <p>{`A small to medium project might be the best start for you. But don't let that stop you from trying to contribute to bigger ones, if you are using some open source software and you really want to help out, the motivation alone will take you far.`}</p>
    <h2>{`Be Polite and Friendly`}</h2>
    <p>{`Open Source is maintained mostly by volunteers. People like you and me who dedicate our time to a project because we like it and it gives us pleasure to work on it.  Make sure you are polite and friendly when talking with other people in your Pull Request or issue.`}</p>
    <h2>{`Have Fun`}</h2>
    <p>{`Contributing to Open Source should be fun for you and you should do it because you really want to. Don't do it just for the sake of it or if you don't enjoy it. You can learn a lot from Open Source and you can learn a lot by creating your own projects. `}</p>
    <p>{`Do what makes you happy and keep learning!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      